<template>
  <van-swipe
    class="my-swipe"
    :autoplay="3000"
    indicator-color="white"
  >
    <van-swipe-item
      v-for="(item,index) in list"
      :key="index"
    >
      <img v-lazy="item.img" />
    </van-swipe-item>
  </van-swipe>
</template>

<script>
export default {
  props: {
    list: {
      type: Array
    }
  },
};
</script>

<style lang="less" scoped>
.van-swipe {
  height: 8rem;
  border-radius: 0.5rem;
  overflow: hidden;
  /deep/.van-swipe__track {
    .van-swipe-item {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
<template>
  <!-- 首页方案二 -->
  <div class="service">
    <Scroll
      :class="$store.state.hidshow ? 'region-scroll' : 'region-scroll-keyboard'"
      ref="scroll"
    >
      <div class="service-box">
        <div class="service-head-box">
          <img
            style="height:2.5rem"
            src="../../static/img/home/homess.webp"
            alt=""
          />
          <!-- 搜索 -->
          <div class="search-box">
            <van-search
              class="search"
              ref="inputVal"
              v-model="value"
              shape="round"
              placeholder="搜索科室"
              @blur="input"
            />
            <!-- 头像 -->
            <div class="head-portrait" @click="switchClick">
              <svg-com
                href="#icon-ziyuan-fuyou-copy"
                size="2rem"
              />
              <!-- <img v-else :src="$store.state.headPortrait" alt="" /> -->
            </div>
          </div>
          <!-- 轮播图 -->
          <Swiper :list="list" />
        </div>
        <div>
          <!-- 绑卡 -->
          <div
            v-if="$store.state.cardList.length == 0"
            class="binding-card"
            @click="switchClick"
          >
            <ul>
              <li class="binding-left">
                <svg-com href="#icon-zhuce1-fuyou-copy" size="3rem" />
              </li>
              <li class="binding-center">
                <p class="in-size">绑卡操作</p>
                <span class="small-six-size">
                  您还没有绑定院内卡,先来绑卡操作吧!
                </span>
              </li>
              <li>
                <svg-com href="#icon-youjiantou1-copy" />
              </li>
            </ul>
          </div>
          <!-- 在线挂号和网上预约 -->
          <div class="registration-appointment">
            <div class="registration" @click="registerskip">
              <div>
                <p class="in-size">在线挂号</p>
                <span class="small-six-size">当天看病 提前挂号</span>
              </div>
              <svg-com
                href="#icon-woyaokanbing"
                size="3rem"
                class="opacity-svg"
              />
            </div>
            <div class="appointment" @click="payskip">
              <div>
                <p class="in-size">在线缴费</p>
                <span class="small-six-size">上缴费用 缴费结算</span>
              </div>
              <svg-com href="#icon-yuyue1" size="3.5rem" class="opacity-svg" />
            </div>
          </div>
          <!-- <Classification
            :type="typeFront"
            title="诊前服务"
          />
          <Classification
            :type="typeIn"
            title="诊中服务"
          />
          <Classification
            :type="typeAfter"
            title="诊后服务"
          /> -->
          <Classification :type="type" title="智慧服务" />
        </div>
      </div>
      <!-- 健康资讯 -->
      <!-- <div class="healthmessage">
        <div class="small-title">
          <h3 class="in-size">健康资讯</h3>
        </div>
        <div
          v-for="(item,index) in newslist"
          :key="index"
          @click="newsdetails(item.id)"
        >
          <div class="msitem">
            <div class="msitem-left">
              <h5>{{item.title}}</h5>
              <span class="mscontent small-size">{{item.description}}</span>
            </div>
            <div class="msitem-right">
              <img
                v-lazy="item.titleImg"
                alt=""
              >
            </div>
          </div>
        </div>
        <div
          class="more"
          v-if="newlist.length > 3"
          @click="$router.push('/detailnews')"
        >显示更多
          <svg
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href='#icon-youjiantou1-copy'></use>
          </svg>
        </div>
      </div> -->
    </Scroll>
  </div>
</template>

<script>
import Swiper from '@/components/content/swiper/Swiper'
import Scroll from '@/components/content/scroll/BScroll'
import Classification from '@/components/common/classification/classification'

import { getMenuInfo, news } from '@/network/service'
import { newsdate } from '@/common/utils'
import { JUMP_POST } from '@/store/mutation-types.js'

export default {
  name: 'serviceSchemeOne',
  components: {
    Swiper,
    Scroll,
    Classification,
  },
  data() {
    return {
      value: '',
      //智慧服务
      type: [],
      //诊前
      typeFront: [],
      //诊中
      typeIn: [],
      //诊后
      typeAfter: [],
      //第一次加载不重新计算高度
      once: false,
      list: [
        {
          img: require('@/static/img/home/homes.webp'),
        },
      ],
      docmHeight: 0, //默认屏幕高度
      //新闻数据
      newslist: [],
      newlist: [],
    }
  },
  activated() {
    if (this.once) {
      this.refreshCall()
    }
  },
  mounted() {
    //首页功能模块
    this.getMenuInfoPost()
    //获取绑卡信息
    this.$store.commit('USER_POST', { _this: this })
    //屏幕大小
    this.size()
    //新闻请求
    this.newsPost()
  },
  methods: {
    //屏幕大小(安卓键盘tabbar问题)
    size() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      this.docmHeight = document.documentElement.clientHeight //获取当前屏幕高度
      window.onresize = () => {
        //屏幕高度变化时判断
        if (isiOS) {
          return (() => {
            let showHeight = document.body.clientHeight
            this.$store.commit(
              'HIDSHOW_TRIGGER',
              this.docmHeight > showHeight + '60' ? false : true
            )
          })()
        } else {
          return (() => {
            let showHeight = document.body.clientHeight
            this.$store.commit(
              'HIDSHOW_TRIGGER',
              this.docmHeight > showHeight ? false : true
            )
          })()
        }
      }
    },
    //重新计算高度
    refreshCall() {
      this.$nextTick(() => {
        this.$refs.scroll.refresh()
      })
    },
    input() {
      if (this.value.trim().length != 0) {
        this.$router.push({ path: '/type', query: { search: this.value } })
      }
    },
    //首页功能模块
    async getMenuInfoPost() {
      let res = await getMenuInfo()
      if (res.code == '0') {
        this.$store.state.functionalModule = res.data
        this.once = true
        //智慧服务
        let data = res.data.sort((a, b) => a.sort - b.sort)
        data.forEach(item => {
          if (item.appRoleId == 1 && item.pid == 2) {
            this.$store.state.typeList.push(item)
            this.typeFront.push(item)
          } else if (item.appRoleId == 1 && item.pid == 3) {
            this.typeIn.push(item)
          } else if (item.appRoleId == 1 && item.pid == 4) {
            this.typeAfter.push(item)
          } else if (item.appRoleId == 1 && item.pid == 5) {
            this.type.push(item)
          }
        })
        this.refreshCall()
      }
    },
    //用户绑卡或切换
    switchClick() {
      if (this.$store.state.nameState != '未登录') {
        this.$router.push('/user-data')
      } else {
        this.$router.push('/login')
      }
    },

    //请求新闻数据
    async newsPost() {
      let date = newsdate()
      let res = await news({
        // begin_date: date[0],
        begin_date: '2020-01-01',
        end_date: date[1],
      })
      this.newlist = res.data.content
      this.newlist.forEach(el => {
        el.titleImg =
          'http://weixin.nhdyyy.cn/weixin/handHospital' + el.titleImg
      })
      if (this.newlist.length < 5) {
        this.newslist = this.newlist
      } else {
        this.newslist = this.newlist.slice(0, 5)
      }
      this.$nextTick(() => {
        this.$refs.scroll.refresh()
      })
    },
    //挂号跳转
    registerskip() {
      this.$dialog
        .confirm({
          title: '挂号须知',
          message: `一、注册建卡
我院实行实名制就诊，请准确填写就诊人真实信息<span style='color:red'>（儿童挂号请填写儿童本人信息）</span>，初次建卡者请使用在线建档，曾办理我院就诊卡者选择添加就诊人，默认就诊人后即可使用。
\n 二、挂号
您可预约7天以内所有科室开放号源，我院挂号系统分当日挂号和预约挂号，请您认真选择。<span style='color:red'>若您持有“河北省无偿献血荣誉卡”，请在就诊前至人工窗口出示相关证件，更换免费号。</span>
\n三、取号
到达诊区后，请先到自助机取号，方可候诊。<span style='color:red'>上午就诊患者，11:30之前取号。下午就诊患者，17:00之前取号。17:00后未取号者预约作废，不予退号。</span>
\n四、退号
为节约医疗资源，请您谨慎对待退号事宜，医生接诊后，将不予办理退号。如需退换号请于当日就诊前办理。
`,
          messageAlign: 'left',
          allowHtml: true,
        })
        .then(() => {
          let ghstatitem = this.type.filter(el => {
            if (el.name == '门诊挂号') {
              return el
            }
          })
          this.$store.commit(JUMP_POST, {
            reg_type: '0',
            menuName: '挂号',
            reg_class: '1',
            _this: this,
            path: '/type',
            flag: 0,
            id: 1,
            title: '在线挂号',
            stat: ghstatitem[0].stat,
          })
        })
        .catch(() => {
          this.$dialog.close()
        })

      // this.$router.push({path:'/type',query:{id:1,title:'在线挂号',flag:0}})
    },
    payskip() {
      let jfstatitem = this.type.filter(el => {
        if (el.name == '缴费结算') {
          return el
        }
      })
      console.log(jfstatitem)
      this.$store.commit(JUMP_POST, {
        reg_type: '1',
        menuName: '缴费',
        reg_class: '1',
        _this: this,
        path: '/pay',
        flag: 1,
        id: 1,
        title: '在线缴费',
        stat: jfstatitem[0].stat,
      })
    },
    //新闻详情跳转
    newsdetails(id) {
      this.$router.push({ path: '/news', query: { id } })
    },
  },
}
</script>
<style lang="less" scoped>
@ff: #ffffff;
/deep/input::-webkit-input-placeholder,
/deep/.van-icon-search,
/deep/.van-field__control {
  /* WebKit browsers */
  color: @ff;
  font-size: 0.8rem;
}
.small-title {
  padding: 1rem 1rem 0.8rem 0.8rem;
  border-top: 0.6rem solid @lineColor;
  // background-image: url("../../static/img/home/椭圆.png");
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: -4%;
}

.service {
  margin-bottom: 1rem;
  background-color: @backgroundColor !important;
  .region-scroll {
    overflow: hidden;
    height: calc(100vh - 3.75rem);
  }
  .region-scroll-keyboard {
    overflow: hidden;
    height: 100vh;
  }
  .search-box {
    display: flex;
    align-items: center;
    height: 3rem;
    margin-top: 0.5rem;
    .search {
      flex: 0.9;
      background: rgba(0, 0, 0, 0);
      margin-left: -0.6rem;
      .van-search__content {
        background: rgba(255, 255, 255, 0.3);
      }
    }
    .head-portrait {
      flex: 0.1;
      height: 2rem;
      width: 2rem;
      text-align: center;
      img {
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  .search-color {
    background-image: linear-gradient(120deg, #50d996 0%, #3cc7b6 100%);
  }
  .service-box {
    margin-bottom: 1rem;
    .service-head-box {
      background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgb(255, 255, 255, 0.8) 70%
        ),
        url('../../static/img/home/background.webp');
      padding: 1rem 0.5rem 0rem 0.5rem;
      margin-bottom: 0.7rem;
      background-size: 100% 20rem;
      background-repeat: no-repeat;
    }
    .binding-card {
      margin: auto;
      box-sizing: border-box;
      width: 95vw;
      padding: 1.5rem 1rem;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .binding-left {
          flex: 0.2;
          svg-com {
            font-size: 3rem;
          }
        }
        .binding-center {
          display: flex;
          flex-direction: column;
          flex: 0.8;
          white-space: nowrap;
          span {
            line-height: 1.5rem;
          }
          span:last-child {
            color: rgb(153, 150, 150);
          }
        }
      }
    }
    .registration-appointment {
      display: flex;
      margin: 0.8rem auto;
      justify-content: space-between;
      width: 95vw;
      .registration,
      .appointment {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 0.8rem 0;
        color: #ffffff;
        width: 49%;
        line-height: 1.3rem;
        border-radius: 0.5rem;
        box-sizing: border-box;
        white-space: nowrap;
        box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
        .opacity-svg {
          opacity: 0.5;
        }
      }
      .registration {
        background-image: linear-gradient(to right, #f4628d 0%, #fab0c7 100%);
      }
      .appointment {
        background-image: linear-gradient(to right, #ff9046 0%, #f7c890 100%);
      }
    }

    .ts-h3 {
      margin: 1rem 0 0.5rem 0;
      padding: 0 1rem;
    }
    .ts-list {
      padding: 0 1rem;
    }
    .jk-hd {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      margin-top: 1rem;
    }
    .jk-list {
      padding: 0 1rem;
      margin-top: 1rem;
    }
  }
  .healthmessage {
    .msitem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid @lineColor;
      padding: 0.8rem;
      box-sizing: border-box;
      .msitem-left {
        display: flex;
        flex-direction: column;
        flex: 0.55;
        h5 {
          // font-size: 0.8rem;
          -webkit-line-clamp: 2;
        }
        .mscontent {
          // text-indent: 2rem;
          -webkit-line-clamp: 2;
          padding-top: 0.5rem;
        }
        h5,
        .mscontent {
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
      .msitem-right {
        flex: 0.4;
        height: 5.75rem;
        width: 8rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 0.3rem;
        }
      }
    }
    .more {
      height: 3rem;
      line-height: 3rem;
      text-align: center;
      .icon {
        height: 1.5rem;
        width: 1.5rem;
        // padding-top: 0.9rem;
      }
    }
  }
}
</style>

<template>
  <div>
    <!-- 常用功能组件 -->
    <div class="small-title">
      <h3 class="in-size" style="margin-left:0.8rem" slot="title">
        {{ title }}
      </h3>
    </div>
    <!-- 智慧服务 -->
    <div class="list">
      <div
        class="list-each"
        v-for="item in type"
        :key="item.id"
        @click="clickJump(item.url, item.name, item.stat, item.flag)"
      >
        <svg class="icon" aria-hidden="true">
          <use
            :xlink:href="
              '#' +
                (Object.keys(item.logo).length !== 0
                  ? item.logo
                  : 'icon-guanbi')
            "
          ></use>
        </svg>
        <span class="small-size">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { times } from '@/common/utils'
export default {
  props: ['type', 'title'],
  methods: {
    //跳转
    clickJump(path, menuName, stat, flag) {
      if (stat == 0) {
        if (menuName == '门诊挂号') {
          this.$dialog
            .confirm({
              title: '挂号须知',
              message: `一、注册建卡
我院实行实名制就诊，请准确填写就诊人真实信息<span style='color:red'>（儿童挂号请填写儿童本人信息）</span>，初次建卡者请使用在线建档，曾办理我院就诊卡者选择添加就诊人，默认就诊人后即可使用。
\n 二、挂号
您可预约7天以内所有科室开放号源，我院挂号系统分当日挂号和预约挂号，请您认真选择。<span style='color:red'>若您持有“河北省无偿献血荣誉卡”，请在就诊前至人工窗口出示相关证件，更换免费号。</span>
\n三、取号
到达诊区后，请先到自助机取号，方可候诊。<span style='color:red'>上午就诊患者，11:30之前取号。下午就诊患者，17:00之前取号。17:00后未取号者预约作废，不予退号。</span>
\n四、退号
为节约医疗资源，请您谨慎对待退号事宜，医生接诊后，将不予办理退号。如需退换号请于当日就诊前办理。
`,
              messageAlign: 'left',
              allowHtml: true,
            })
            .then(() => {
              if (
                times(false).split(' ')[0] == '2021-12-30' ||
                times(false).split(' ')[0] == '2021-12-31'
              ) {
                this.$dialog.close()
                this.$toast('非常抱歉，12月30日和31日暂无法挂号')
              } else {
                this.$store.dispatch('JUMP', {
                  menuName,
                  _this: this,
                  path,
                  flag,
                })
              }
            })
            .catch(() => {
              this.$dialog.close()
            })
        } else {
          this.$store.dispatch('JUMP', { menuName, _this: this, path, flag })
        }
      } else {
        if (this.$store.state.cardList[0].power == 1) {
          this.$store.dispatch('JUMP', { menuName, _this: this, path, flag })
        } else {
          this.$toast('维护中')
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  margin: 1rem 0;
  .list-each {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 6rem;
    width: 25%;
    .icon {
      font-size: 3rem;
    }
    span {
      margin-top: 0.5rem;
    }
  }
}
</style>
